@media screen and (max-width: 450px) {
  #features {
    padding: 20px;
    width: 111%;
  }
  #about,
  #services,
  #testimonials,
  #team,
  #contact,
  #experience,
  #footer {
    width: 111%;
  }

  #portfolio {
    width: 110%;
  }

  #carousel {
    width: 111%;
    /* padding-top: 40px; */
    /* padding-top: calc(15px + 24px + 2 * 8px + 2 * 10px + 5px + 15px); */
    padding-top: 80px;
    background-color: #EEEEEE;
  }

  #carousel h3{
    font-size: calc(16px + 2vw);
  }

  #carousel p{
    font-size: calc(10px + 1vw);
  }
}
